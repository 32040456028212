import React from 'react'
import './New.css'
// import {PiWhatsappLogoLight} from 'react-icon/pi';
import logo from '../assest/reddy.png'
import online_id from '../assest/online_id.png'
import whatsapp from '../assest/whatsapp.webp'
import go from '../assest/go.jpeg';
import sky from '../assest/sky.jpeg'
import lotus from '../assest/lotus.jpeg'
import diamond from '../assest/diamond.jpg'

import { FaCheck } from "react-icons/fa";

export default function New() {
  return (
    <>

        <div className='section-1'> 

            <a href="https://wa.me/+12345298031"><img src={logo} alt="" /></a>
            <a href="https://wa.me/+12345298031"><p className='gn-id'>GENUINE ONLINE ID</p></a>
            <a href="https://wa.me/+12345298031"><h2 className='reddy'>All Panel Provider and Sharing Available</h2></a>
            <div className='exch'>
                <img src={go} alt="Go Exchange" />
                <img src={sky} alt="Sky Exchange" />
                <img src={lotus} alt="Lotus Exchange" />
                <img src={diamond} alt="Diamond Exchange" />
                
                
            </div>
            <a href="https://wa.me/+12345298031"><p>Reddy is the Best ID Sevice Provider in India. Get India's Genuine ID. Reddy Anna</p></a>
            <a href="https://wa.me/+12345298031"><img src={whatsapp} alt="" /></a>
        </div>

        <div className='section-2'>

            <a href="https://wa.me/+12345298031"><h1 className='num'>1</h1></a>
            <a href="https://wa.me/+12345298031"><h1 className='word'>Create Account</h1></a>

            <a href="https://wa.me/+12345298031"><h1 className='num'>2</h1></a>
            <a href="https://wa.me/+12345298031"><h1 className='word'>Join Reddy Anna</h1></a>

            <a href="https://wa.me/+12345298031"><h1 className='num'>3</h1></a>
            <a href="https://wa.me/+12345298031"><h1 className='word'>Discover</h1></a>
            
        

            
        </div>

        <div className='section-3'>
            <div>
                <a href="https://wa.me/+12345298031"><img src={logo} alt="" /></a>
            </div>
            <a href="https://wa.me/+12345298031"><img src={online_id} alt="" /></a>
        </div>

        <div className='section-4'>
            <div className='sub-section-1'>
                <a href="https://wa.me/+12345298031"><h2>Open Your Demo Account Instant</h2></a>
                <a href="https://wa.me/+12345298031"><p>India's No.1 Leading Online Id Provider Get Your Id in Just 2 Mins. Join India's Most Affordable , Reliable & Trusted ID...</p></a>
                <a href="https://wa.me/+12345298031"><img src={whatsapp} alt="" /></a>
            </div>
            <br />
            <div className='sub-section-2'>
                <a href="https://wa.me/+12345298031"><h2>Why Choose Reddy Anna?</h2></a>
                <div className='para_list'>
                    <div className='div1'>
                        <a href="https://wa.me/+12345298031"><p><FaCheck /> Most Trusted Platform</p></a>
                        <a href="https://wa.me/+12345298031"><p><FaCheck />Fastest Reply Within 59 Seconds</p></a>
                        <a href="https://wa.me/+12345298031"><p><FaCheck />Anytime Instant Withdrawal</p></a>
                        

                    </div>

                    <div className='div2'>
                        <a href="https://wa.me/+12345298031"><p><FaCheck />24x7 Customer Support</p></a>
                        <a href="https://wa.me/+12345298031"><p><FaCheck />100% Transfer 7 Accuracy</p></a>

                    </div>
                </div>
            </div>
        </div>
        

       
    </>
  )
}
