
import './App.css';
import New from './NewProject/New';



function App() {
  return (
    <div className="App">
      
      <New />
    </div>
  );
}

export default App;
